<template>
  <show-component />
</template>

<script>
import ShowComponent from '@/components/messages/Show'

export default {
  components: {
    ShowComponent
  }
}
</script>